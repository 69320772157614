import * as dynamic from 'utils/dynamic';

export const SELECT_CUSTOMER_INVOICE_EXTENDED = dynamic.select(
  'id',
  'invoiceNumber',
  'customerID',
  'invoiceTypeID',
  'invoiceType.title as invoiceTypeTitle',
  'poNumber',
  'remarks',
  'customerInvoiceDetails.count() as detailsCount',
  'isActive',
  'invoiceDate',
  'paymentDate',
  'isPaid',
  'isSent',
  'invoiceURL',
  'ignore',
  'internalRemarks',
  'cardRemarks',
  'customerContact.name as contactName',
);

export const SELECT_CUSTOMER_GRID = dynamic.select(
  'id',
  'invoiceDate',
  'invoiceNumber',
  'customerInvoiceDetails.sum(s=>s.amount * s.quantity) as totalAmount',
  'isActive',
  'paymentDate',
  'invoiceURL',
  'isPaid',
  'isSent',
  'customerID',
  'invoiceTypeID',
  'invoiceType.title as invoiceTypeTitle',
  'invoiceType.paymentRequired',
  'internalRemarks',
  'cardRemarks',
  'invoiceType.step',
  'customerInvoiceDetails',
  'ignore',
  'customerContact.name as contactName',
);
