import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ReferralTypes';

export const API_REFERRAL_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ReferralType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ReferralType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IReferralType
  extends FixServerObject<
    DynamicComponents.Schemas.ReferralType,
    'id' | 'rowIndex' | 'isOther' | 'title' | 'isActive'
  > {}

export const schemaReferralType = yup.object({
  title: yup.string().required('rule-required'),
});

export class ReferralType implements yup.InferType<typeof schemaReferralType> {
  id!: string;
  title = '';
  isActive = true;
  isOther = false;
  rank = 0;
}

export interface IGridReferralType
  extends Pick<IReferralType, 'id' | 'title' | 'rowIndex' | 'isActive' | 'isOther' | 'rank'> {}
