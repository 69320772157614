import { isValid, max, min, toDate as fnsToDate } from 'date-fns';

export type DateValue = Date | string | number | null | undefined;

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export function isFullISODateTimeString(dateString: string): boolean {
  const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|([+-]\d{2}:\d{2}))?$/;
  return isoDateTimeRegex.test(dateString);
}

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};
export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};
