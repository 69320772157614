import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'PaymentAccounts';

export const API_PAYMENT_ACCOUNTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<PaymentAccounts, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<PaymentAccounts, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IPaymentAccounts
  extends FixServerObject<DynamicComponents.Schemas.PaymentAccount, 'id'> {}

type SchemaFields = Pick<IPaymentAccounts, 'title'>;

export const schemaPaymentAccounts: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
});

export class PaymentAccounts implements yup.InferType<typeof schemaPaymentAccounts> {
  id!: string;
  title = '';
  isActive = true;
  accountCode = '';
}

export interface IGridPaymentAccounts
  extends Pick<IPaymentAccounts, 'id' | 'title' | 'rowIndex' | 'isActive' | 'accountCode'> {}

export interface IDashboardPaymentAccountsArgs {
  search: string;
  orderBy: DynamicOrder;
}
export interface IDashboardPaymentAccounts
  extends Pick<IPaymentAccounts, 'id' | 'title' | 'accountCode'> {}
