import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Genders';

export const API_GENDERS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Gender, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Gender, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IGender extends FixServerObject<DynamicComponents.Schemas.Gender, 'id'> {}

type SchemaFields = Pick<IGender, 'title'>;

export const schemaGenders: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
});

export class Gender implements yup.InferType<typeof schemaGenders> {
  id!: string;
  isOther = false;
  title = '';
  isActive = true;
}

export interface IGridGender
  extends Pick<IGender, 'id' | 'title' | 'rowIndex' | 'isActive' | 'isOther'> {
  numberOfPharmacists: number;
}
