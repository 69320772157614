import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'MemberTypes';

export const API_MEMBER_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<MemberType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<MemberType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IMemberType extends FixServerObject<DynamicComponents.Schemas.MemberType, 'id'> {}

type SchemaFields = Pick<IMemberType, 'title'>;

export const schemaMemberTypes: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
});

export class MemberType implements yup.InferType<typeof schemaMemberTypes> {
  id!: string;
  title = '';
  isActive = true;
}

export interface IGridMemberType
  extends Pick<IMemberType, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}
