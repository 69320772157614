import { AxiosRequestConfig } from 'axios';
import React, { memo, useEffect } from 'react';
import { apiAppBackend, apiAppDynamic, prepareRequestData } from 'utils/service';

const instances = [apiAppDynamic, apiAppBackend];

const interceptor = (conf: AxiosRequestConfig) => {
  if (conf.method === 'post' && conf.data && typeof conf.data === 'object') {
    conf.data = prepareRequestData(conf.data);
  }
  return conf;
};

interface Props {
  children: React.ReactNode;
}
export const AxiosInterceptorPost = memo<Props>(({ children }) => {
  useEffect(() => {
    const interceptors = instances.map((instance) => {
      const id = instance.interceptors.request.use(interceptor);
      return { instance, id };
    });
    return () => {
      interceptors.forEach(({ id, instance }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{children}</>;
});
