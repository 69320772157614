import * as dynamic from 'utils/dynamic';
import * as yup from 'yup';

const API = 'Labels';

export const API_LABELS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ILabel, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ILabel, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ILabel extends DynamicComponents.Schemas.Label {
  id: string;
}

export const schemaLabels = yup.object({
  title: yup.string().required('rule-required').default(''),
  labelKey: yup.string().required('rule-required').trim().default(''),
  languageID: yup.string().required('rule-required').default(''),
  labelSourceID: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export interface GridLabel extends Pick<ILabel, 'id' | 'labelKey' | 'title' | 'isActive'> {
  languageTitle: string;
}
export interface GridLabelsInput {
  search: string;
  take?: number;
  skip?: number;
  orderBy: dynamic.DynamicOrder;
  languageID: string;
  labelSourceID: string;
}
