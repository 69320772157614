import { AppLoading } from 'components/app-loading';
import { APP_ROUTES } from 'configs';
import {
  CmsPagesLayout,
  CmsSettingsLayout,
  ConsultedFormSettingsLayout,
  EmptyLayout,
  GeneralSettingsLayout,
  LabelsLayout,
  LoginLayout,
  PrivateLayout,
} from 'layouts';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { PermissionsProtector } from './permissions';

const DashboardPage = React.lazy(() => import('pages/dashboard'));
const PharmacistsListPage = React.lazy(() => import('pages/pharmacists'));
const PharmacistsActivitiesPage = React.lazy(
  () => import(/* webpackChunkName: "pharmacists-activities" */ 'pages/pharmacists-activities'),
);
const OpenInvoicesPage = React.lazy(
  () => import(/* webpackChunkName: "open-invoices" */ 'pages/open-invoices'),
);

const ProfilePage = React.lazy(() => import('pages/profile'));
const PermissionsPage = React.lazy(() => import('pages/employee-permissions'));
const LabelsCrmPage = React.lazy(() => import('pages/labels/labels-crm'));
const LabelsWebsitePage = React.lazy(() => import('pages/labels/labels-website'));
const LanguagesPage = React.lazy(() => import('pages/labels/languages'));
const EmployeesPage = React.lazy(() => import('pages/employee-profiles'));
const EmployeesItemPage = React.lazy(() => import('pages/employee-profile-item'));

const NamePrefixesPage = React.lazy(() => import('pages/settings/general-settings/name-prefixes'));
const NamePrefixesEngPage = React.lazy(
  () => import('pages/settings/general-settings/name-prefixes-eng'),
);
const NationalitiesPage = React.lazy(() => import('pages/settings/general-settings/nationalities'));
const MemberTypesPage = React.lazy(() => import('pages/settings/general-settings/member-types'));
const PaymentTypeSubjectsPage = React.lazy(
  () => import('pages/settings/general-settings/payment-type-subjects'),
);
const PaymentTypesPage = React.lazy(() => import('pages/settings/general-settings/payment-types'));
const RegionsPage = React.lazy(() => import('pages/settings/general-settings/regions'));
const CitiesPage = React.lazy(() => import('pages/settings/general-settings/cities'));
const WorkPlacesPage = React.lazy(() => import('pages/settings/general-settings/work-places'));
const WorkPlaceTypesPage = React.lazy(
  () => import('pages/settings/general-settings/work-place-types'),
);
const GendersPage = React.lazy(() => import('pages/settings/consulted-form-settings/genders'));

const SuppliersPage = React.lazy(() => import('pages/expenses-list/suppliers'));
const InvoicesPage = React.lazy(() => import('pages/expenses-list/invoices'));

const CustomerTypesPage = React.lazy(
  () => import('pages/settings/general-settings/customer-types'),
);

const ConsultSessionsPage = React.lazy(() => import('pages/consult-sessions'));

const PaymentAccountsPage = React.lazy(
  () => import('pages/settings/general-settings/payment-accounts'),
);
const IncomeAccountsPage = React.lazy(
  () => import('pages/settings/general-settings/income-accounts'),
);
const InvoiceTypesPage = React.lazy(() => import('pages/settings/general-settings/invoice-types'));

const LogisticClassificationsPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/logistic-classifications'),
);

const RightsExtractionClassificationsPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/rights-extraction-classifications'),
);

const ClinicalSubjectClassificationsPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/clinical-classification-subjects'),
);

const ConsultRequestTypesPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/consult-request-types'),
);

const InsuranceStatusesPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/insurance-statuses'),
);

const ReferralTypesPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/referral-types'),
);

const MedicalStaffOptionsPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/medical-staff-options'),
);

const MedicalStaffWorkPlacesPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/medical-staff-work-places'),
);

const ResponsivenessToTreatmentsPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/responsiveness-to-treatments'),
);

const ReferenceSourcesPage = React.lazy(
  () => import('pages/settings/consulted-form-settings/reference-sources'),
);

const YearlySubscriptionsPage = React.lazy(() => import('pages/yearly-subscription'));

const CmsPageHomePage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-home" */ 'pages/cms/pages/home'),
);
const CmsPageContactUsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-contact-use" */ 'pages/cms/pages/contact-us'),
);
const CmsPageThanksPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-thanks" */ 'pages/cms/pages/thanks'),
);
const CmsPageSearchPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-search" */ 'pages/cms/pages/search'),
);
const CmsPageCoursesPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-courses" */ 'pages/cms/pages/courses'),
);
const CmsPageForumsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-forums" */ 'pages/cms/pages/forums'),
);
const CmsPageWebinarsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-webinars" */ 'pages/cms/pages/webinars'),
);
const CmsPagePharmaRulesPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-pharma-rules" */ 'pages/cms/pages/pharma-rules'),
);
const CmsPageDrugsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-drugs" */ 'pages/cms/pages/drugs'),
);
const CmsPagePharmacistsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-pharmacists" */ 'pages/cms/pages/pharmacists'),
);
const CmsPageArticlesPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-articles" */ 'pages/cms/pages/articles'),
);
const CmsPageEthicsCodePage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-ethics-code" */ 'pages/cms/pages/ethics-code'),
);
const CmsPageBenefitsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-page-benefits" */ 'pages/cms/pages/benefits'),
);

const CmsArticlesPage = React.lazy(
  () => import(/* webpackChunkName: "cms-articles" */ 'pages/cms/articles'),
);
const CmsPharmaAdvisorPage = React.lazy(
  () => import(/* webpackChunkName: "cms-pharma-advisor" */ 'pages/cms/pharma-advisors'),
);
const CmsPharmaRulesPage = React.lazy(
  () => import(/* webpackChunkName: "cms-pharma-rules" */ 'pages/todo'),
);
const CmsRegDrugsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-reg-drugs" */ 'pages/cms/reg-drugs'),
);
const CmsRegDrugItemPage = React.lazy(
  () => import(/* webpackChunkName: "cms-reg-drug-item" */ 'pages/cms/reg-drug-item'),
);
const CmsSiteParametersPage = React.lazy(
  () => import(/* webpackChunkName: "cms-site-parameters" */ 'pages/cms/site-parameters'),
);
const CmsTeamMembersPage = React.lazy(
  () => import(/* webpackChunkName: "cms-team-members" */ 'pages/cms/team-members'),
);
const CmsArticleSubjectsPage = React.lazy(
  () =>
    import(/* webpackChunkName: "cms-article-subjects" */ 'pages/cms/settings/article-subjects'),
);
const CmsTeamSubjectsPage = React.lazy(
  () => import(/* webpackChunkName: "cms-team-subjects" */ 'pages/cms/settings/team-subjects'),
);

const CustomerListPage = React.lazy(
  () => import(/* webpackChunkName: "customer-list" */ 'pages/customers'),
);
const CustomerItemPage = React.lazy(
  () => import(/* webpackChunkName: "customer-item" */ 'pages/customer-item'),
);

const LoginPage = React.lazy(() => import(/* webpackChunkName: "login" */ 'pages/login'));
const LoginCodePage = React.lazy(
  () => import(/* webpackChunkName: "login-code" */ 'pages/login-code'),
);

const NotFoundPage = React.lazy(() => import('pages/todo'));

export const routes: RouteObject[] = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <DashboardPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PHARMACISTS_LIST.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <PharmacistsListPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.CONSULT_SESSIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ConsultSessionsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PHARMACISTS_ACTIVITIES.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <PharmacistsActivitiesPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.YEARLY_SUBSCRIPTIONS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <YearlySubscriptionsPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.OPEN_INVOICES.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <OpenInvoicesPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.USER_ITEM.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <EmployeesItemPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.USERS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <EmployeesPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.PERMISSIONS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <PermissionsPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.INVOICES.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <InvoicesPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.SUPPLIERS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<AppLoading />}>
              <SuppliersPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.SETTINGS_GENERAL.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <GeneralSettingsLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PaymentTypeSubjectsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.NAME_PREFIXES_ENG.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <NamePrefixesEngPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.NATIONALITIES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <NationalitiesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.MEMBER_TYPES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <MemberTypesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.NAME_PREFIXES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <NamePrefixesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PAYMENT_TYPES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PaymentTypesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.REGIONS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <RegionsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CITIES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CitiesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.WORK_PLACES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <WorkPlacesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.WORK_PLACE_TYPES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <WorkPlaceTypesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CUSTOMER_TYPES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CustomerTypesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PAYMENT_ACCOUNTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PaymentAccountsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.INCOME_ACCOUNTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <IncomeAccountsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.INVOICE_TYPES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <InvoiceTypesPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.CONSULTED_FORM_SETTINGS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <ConsultedFormSettingsLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ConsultRequestTypesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.LOGISTIC_CLASSIFICATIONS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LogisticClassificationsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.GENDERS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <GendersPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.RIGHTS_EXTRACTION_CLASSIFICATIONS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <RightsExtractionClassificationsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CLINICAL_CLASSIFICATIONS_SUBJECTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ClinicalSubjectClassificationsPage />
              </React.Suspense>
            ),
          },

          {
            path: APP_ROUTES.INSURANCE_STATUSES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <InsuranceStatusesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.REFERRAL_TYPES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ReferralTypesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.MEDICAL_STAFF_OPTIONS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <MedicalStaffOptionsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.MEDICAL_STAFF_WORK_PLACES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <MedicalStaffWorkPlacesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.RESPONSIVENESS_TO_TREATMENTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ResponsivenessToTreatmentsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.REFERENCE_SOURCES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ReferenceSourcesPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.LABELS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <LabelsLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsCrmPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.LABELS_WEBSITE.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsWebsitePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.LANGUAGES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LanguagesPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.CMS_PAGES.path,
        element: <CmsPagesLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageHomePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageHomePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageHomePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_CONTACT_US.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageContactUsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_THANKS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageThanksPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_SEARCH.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageSearchPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_COURSES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageCoursesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_FORUMS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageForumsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_WEBINARS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageWebinarsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_PHARMA_RULES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPagePharmaRulesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_DRUGS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageDrugsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_PHARMACISTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPagePharmacistsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_ARTICLES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageArticlesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_ETHICS_CODE.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageEthicsCodePage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_PAGE_BENEFITS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsPageBenefitsPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.CMS_ARTICLES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsArticlesPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_PHARMA_ADVISORS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsPharmaAdvisorPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_PHARMA_RULES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsPharmaRulesPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_REG_DRUGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsRegDrugsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_REG_DRUG_ITEM.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsRegDrugItemPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_SITE_PARAMETERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsSiteParametersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_TEAM_MEMBERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsTeamMembersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_SETTINGS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <CmsSettingsLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsArticleSubjectsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS_TEAM_SUBJECTS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CmsTeamSubjectsPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.CUSTOMERS_LIST.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <EmptyLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CustomerListPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CUSTOMER_ITEM.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <CustomerItemPage />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
