import { add, format } from 'date-fns';
import { AvailableInvoiceNumbersSource } from 'services/user-pharmacist-profile-invoice-details';
import { convertToDate } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import {
  apiAppBackend,
  apiRtk,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { InferType } from 'yup';
import { apiInvoiceTypes, InvoiceStep } from '../invoice-types';
import { ServiceMediaUploads } from '../media-uploads';
import { ServiceUserPharmacistProfiles } from '../user-pharmacist-profiles';
import {
  API_USER_PHARMACIST_PROFILE_INVOICES,
  ConvertUserPharmacistProfileInvoicesInput,
  CreateReceiptUserPharmacistProfileInvoicesInput,
  IDashboardPaymentInvoices,
  IDashboardPaymentInvoicesArgs,
  IGridOpenInvoices,
  IGridOpenInvoicesArgs,
  IGridUserPharmacistProfileInvoices,
  schemaUserPharmacistProfileInvoiceImportFromExcel,
  UserPharmacistProfileInvoices,
  UserPharmacistProfileInvoiceSendEmail,
  UserPharmacistProfileInvoicesExtended,
} from './models';

const REVALIDATE_TAG = 'UserPharmacistProfileInvoiceDetails' as const;

export const makeInvoiceNumber = () => `P${format(new Date(), 'yyyyMMdd.HHmm')}`;

type ApiModel = UserPharmacistProfileInvoices;

export * from './models';

class Service extends DynamicService<ApiModel> {
  convertTo = async (input: BackendComponents.Schemas.UserPharmacistProfileInvoiceConvertDto) => {
    return apiAppBackend.post<BackendComponents.Schemas.UserPharmacistProfileInvoiceConvertDtoOutput>(
      API_USER_PHARMACIST_PROFILE_INVOICES.CONVERT_TO,
      input,
    );
  };
  createReceipt = async (
    input: BackendComponents.Schemas.UserPharmacistProfileInvoiceReceiptDto,
  ) => {
    const { data } =
      await apiAppBackend.post<BackendComponents.Schemas.UserPharmacistProfileInvoiceReceiptDtoOutput>(
        API_USER_PHARMACIST_PROFILE_INVOICES.RECEIPT,
        input,
      );

    return data;
  };
  duplicate = async (invoiceID: string) => {
    return apiAppBackend.post<BackendComponents.Schemas.UserPharmacistProfileInvoiceDuplicateDtoOutput>(
      API_USER_PHARMACIST_PROFILE_INVOICES.DUPLICATE(invoiceID),
    );
  };

  validateExcel = async (url: string) => {
    return this.engine.get(API_USER_PHARMACIST_PROFILE_INVOICES.VALIDATE_EXCEL, {
      params: { excelUrl: url },
    });
  };
  bulkInvoiceDetails = async (input: DynamicComponents.Schemas.BulkInvoiceDetailRequest) => {
    return this.engine.post(
      API_USER_PHARMACIST_PROFILE_INVOICES.UPLOAD_BULK_INVOICE_DETAILS,
      input,
    );
  };
}

export const ServiceUserPharmacistProfileInvoices = new Service({
  getAll: API_USER_PHARMACIST_PROFILE_INVOICES.GET_ALL_DYNAMIC,
  post: API_USER_PHARMACIST_PROFILE_INVOICES.POST,
  patch: API_USER_PHARMACIST_PROFILE_INVOICES.PATCH,
  delete: API_USER_PHARMACIST_PROFILE_INVOICES.DELETE,
});

export interface IGridUserPharmacistProfileInvoicesParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: dynamic.DynamicOrder;
  userPharmacistProfileID: string;
  isActiveOnly: boolean;
}

interface UserPharmacistProfileInvoicesResponse extends UserPharmacistProfileInvoicesExtended {
  userPharmacistProfileInvoiceDetailsItemsCount: number;
  invoiceTypeTitle: string;
}

export const apiUserPharmacistProfileInvoices = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAvailableOpenInvoicesInvoiceNumbersSource: build.query<
      AvailableInvoiceNumbersSource[],
      { isActiveOnly: boolean }
    >({
      // @ts-ignore
      queryFn: async ({ isActiveOnly }) => {
        try {
          const {
            data: { value },
          } = await ServiceUserPharmacistProfileInvoices.getAllDynamic({
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('isPaid', false, dynamic.decoratorIsNotNullable(dynamic.equals)),
                isActiveOnly && dynamic.makeFilter('ignore', false, dynamic.equals),
                dynamic.makeFilter('invoiceType.step', 2, dynamic.equals),
                dynamic.makeFilter('userPharmacistProfileInvoiceDetails.count()', 0, dynamic.more),
              )
              .join('&&'),
            select: dynamic.select('invoiceNumber as title', 'id'),
            count: true,
            orderBy: dynamic.orderBy('invoiceNumber', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getGridOpenInvoices: build.query<
      DynamicResult<IGridOpenInvoices, { count: true }>,
      IGridOpenInvoicesArgs
    >({
      query: ({
        searchFirstName,
        searchLastName,
        date,
        take,
        skip,
        order,
        search,
        invoiceNumber,
        isActiveOnly,
      }) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICES.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter('isPaid', false, dynamic.equals),
              isActiveOnly && dynamic.makeFilter('ignore', false, dynamic.equals),
              dynamic.makeFilter('invoiceType.step', 2, dynamic.equals),
              dynamic.makeFilter('userPharmacistProfileInvoiceDetails.count()', 0, dynamic.more),
              dynamic.makeFilter(
                'userPharmacistProfile.firstName',
                searchFirstName,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter(
                'userPharmacistProfile.lastName',
                searchLastName,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter(
                ['poNumber', 'cardRemarks'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter(
                'invoiceDate',
                date,
                dynamic.decoratorIsNotNullable(dynamic.dateRange),
              ),
              dynamic.makeFilter(
                'invoiceNumber',
                invoiceNumber,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'userPharmacistProfileInvoiceDetails.sum(s=>s.quantity*s.amount) as totalAmount',
            'invoiceNumber',
            'invoiceDate',
            'poNumber',
            'userPharmacistProfile.fullName as name',
            'cardRemarks',
            'isSent',
            'invoiceURL',
            'userPharmacistProfileID',
            'userPharmacistProfileContact.name as contactName',
          ),
          count: true,
          take,
          skip,
          orderBy: dynamic.orderBy(order.field, order.order),
        },
      }),
    }),
    getGridUserPharmacistProfileInvoices: build.query<
      DynamicResult<IGridUserPharmacistProfileInvoices, { count: true }>,
      IGridUserPharmacistProfileInvoicesParams
    >({
      queryFn: async ({ skip, take, userPharmacistProfileID, search, orderBy, isActiveOnly }) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'invoiceDate',
              'invoiceNumber',
              'userPharmacistProfileInvoiceDetails.sum(s=>s.amount * s.quantity) as totalAmount',
              'isActive',
              'paymentDate',
              'invoiceURL',
              'isPaid',
              'isSent',
              'userPharmacistProfileID',
              'invoiceTypeID',
              'invoiceType.title as invoiceTypeTitle',
              'invoiceType.paymentRequired as paymentRequired',
              'internalRemarks',
              'cardRemarks',
              'invoiceType.step as step',
              'ignore',
              'userPharmacistProfileContact.name as contactName',
            ),
            filter: dynamic
              .mergeFilters(
                isActiveOnly && dynamic.makeFilter('ignore', false, dynamic.equals),
                dynamic.makeFilter(
                  'invoiceNumber',
                  search,
                  dynamic.decoratorIsNotNullable(dynamic.contains),
                ),
                dynamic.makeFilter(
                  'userPharmacistProfileID',
                  userPharmacistProfileID,
                  dynamic.equals,
                ),
              )
              .join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            skip,
            take,
          };
          const { data } = await ServiceUserPharmacistProfileInvoices.getAllDynamic<
            IGridUserPharmacistProfileInvoices,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getDashboardPaymentInvoices: build.query<
      IDashboardPaymentInvoices[],
      IDashboardPaymentInvoicesArgs
    >({
      query: ({ search, date, orderBy }) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select(
            'id',
            'paymentDate',
            'isPaid',
            'invoiceNumber',
            'invoiceURL',
            'userPharmacistProfile.fullName as paidBy',
            'userPharmacistProfileInvoiceDetails.sum(s=>s.quantity*s.amount) as totalAmount',
            'userPharmacistProfileID',
          ),
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                [
                  'invoiceNumber',
                  'userPharmacistProfile.firstName',
                  'userPharmacistProfile.lastName',
                ],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter('invoiceURL', null, dynamic.notEquals),
              dynamic.makeFilter(
                'paymentDate',
                date,
                dynamic.decoratorIsNotNullable(dynamic.dateRange),
              ),
            )
            .join('&&'),
          orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfileInvoices: build.query<UserPharmacistProfileInvoicesResponse, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'invoiceNumber',
              'userPharmacistProfileID',
              'invoiceTypeID',
              'invoiceType.title as invoiceTypeTitle',
              'poNumber',
              'remarks',
              'userPharmacistProfileInvoiceDetails.count() as userPharmacistProfileInvoiceDetailsItemsCount',
              'isActive',
              'invoiceDate',
              'paymentDate',
              'isPaid',
              'isSent',
              'invoiceURL',
              'ignore',
              'internalRemarks',
              'cardRemarks',
              'userPharmacistProfileContact.name as contactName',
            ),
          };
          const { data } =
            await ServiceUserPharmacistProfileInvoices.getDynamic<UserPharmacistProfileInvoicesResponse>(
              id,
              params,
            );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getUserPharmacistProfileInvoicesValidateExcel: build.query<
      IDashboardPaymentInvoices[],
      { excelUrl: string }
    >({
      queryFn: async ({ excelUrl }) => {
        try {
          const result = await ServiceUserPharmacistProfileInvoices.engine.get(
            API_USER_PHARMACIST_PROFILE_INVOICES.VALIDATE_EXCEL,
            {
              params: {
                excelUrl,
              },
            },
          );
          return result;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    postUserPharmacistProfileInvoices: build.mutation<
      UserPharmacistProfileInvoices,
      UserPharmacistProfileInvoices
    >({
      queryFn: async (customer) => {
        try {
          return await ServiceUserPharmacistProfileInvoices.post(customer);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserPharmacistProfileInvoices: build.mutation<
      void,
      PatchPartial<UserPharmacistProfileInvoices, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfileInvoices.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    deleteUserPharmacistProfileInvoices: build.mutation<
      void,
      PatchPartial<UserPharmacistProfileInvoices, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfileInvoices.delete(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),

    sendUserPharmacistProfileInvoices: build.mutation<void, UserPharmacistProfileInvoiceSendEmail>({
      query: (data) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICES.SEND_EMAIL,
        method: 'post',
        data,
      }),
    }),
    convertUserPharmacistProfileInvoices: build.mutation<
      { id: string },
      ConvertUserPharmacistProfileInvoicesInput
    >({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceUserPharmacistProfileInvoices.convertTo(input);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    createReceiptUserPharmacistProfileInvoices: build.mutation<
      { id: string },
      Omit<CreateReceiptUserPharmacistProfileInvoicesInput, 'invoiceTypeID'>
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const requestInvoiceType = dispatch(
            apiInvoiceTypes.endpoints.getInvoiceTypesOption.initiate(),
          );
          requestInvoiceType.unsubscribe();

          const invoiceTypes = await requestInvoiceType.unwrap();

          const invoiceType = invoiceTypes.find(({ step }) => step === InvoiceStep.COMPLETE);

          if (!invoiceType) {
            return { error: new Error('invoiceTypeID is required') };
          }
          const result = await ServiceUserPharmacistProfileInvoices.createReceipt({
            ...input,
            invoiceTypeID: invoiceType.id,
          });

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    duplicateUserPharmacistProfileInvoice: build.mutation<{ id: string }, string>({
      queryFn: async (invoiceID) => {
        try {
          const { data } = await ServiceUserPharmacistProfileInvoices.duplicate(invoiceID);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    importFromExcelUserPharmacistProfileInvoice: build.mutation<
      void,
      InferType<typeof schemaUserPharmacistProfileInvoiceImportFromExcel>
    >({
      queryFn: async (input) => {
        try {
          const { file, fromDate, toDate } = input;

          const originalDateFromDate = convertToDate(fromDate);
          const originalDateToDate = convertToDate(toDate);

          const timeZoneOffsetInMinutes = Math.abs(new Date().getTimezoneOffset());

          const adjustedFromDate = add(originalDateFromDate, { minutes: timeZoneOffsetInMinutes });
          const adjustedToDate = add(originalDateToDate, { minutes: timeZoneOffsetInMinutes });

          const {
            data: { filePath },
          } = await ServiceMediaUploads.uploadFile(file);

          await ServiceUserPharmacistProfileInvoices.validateExcel(filePath);

          await ServiceUserPharmacistProfiles.createUsersFromExcel(filePath);

          await ServiceUserPharmacistProfileInvoices.bulkInvoiceDetails({
            invoiceID: input.userPharmacistProfileInvoiceID,
            excelUrl: filePath,
            paymentTypeID: input.paymentTypeID,
            incomeAccountID: input.incomeAccountID,
            duration: String(input.duration),
            quantity: input.quantity,
            amount: input.amount,
            fromDate: adjustedFromDate.toISOString(),
            toDate: adjustedToDate.toISOString(),
            remarks: input.remarks,
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
