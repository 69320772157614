import { apiAppDynamic } from 'utils/service';
import {
  API_CARDCOM_SERVICES,
  ICreateInvoiceForWireTransfer,
  ICreateInvoiceWithoutPayment,
  ICreatePaymentPage,
} from './models';

export * from './models';

class Service {
  async createPaymentPage(data: ICreatePaymentPage) {
    return apiAppDynamic.post(API_CARDCOM_SERVICES.CREATE_PAYMENT_PAGE, data);
  }
  async createInvoiceWithoutPage(data: ICreateInvoiceWithoutPayment) {
    return apiAppDynamic.post(API_CARDCOM_SERVICES.CREATE_INVOICE_WITHOUT_PAGE, data);
  }
  async createInvoiceForWireTransfer(data: ICreateInvoiceForWireTransfer) {
    return apiAppDynamic.post(API_CARDCOM_SERVICES.CREATE_INVOICE_FOR_WIRE_TRANSFER, data);
  }
}

export const ServiceCardcom = new Service();
