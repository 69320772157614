import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.customers;

export const selectCustomersStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectCustomersBlockedInvoices = createSelector(selectState, ({ blockedInvoices }) => {
  return blockedInvoices;
});
export const selectCustomersData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectCustomersFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectCustomersPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectCustomersOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
