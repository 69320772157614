export const NAMESPACE = 'USER_PHARMACIST_PROFILES';

export enum BlockedInvoiceReason {
  DUPLICATE = 'duplicate',
  RECEIPT = 'receipt',
  CONVERT = 'convert',
  IMPORT_EXCEL = 'import-excel',
}

export type BlockedInvoice = {
  reason: BlockedInvoiceReason;
  startTimestamp: number;
};
