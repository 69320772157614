import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { FixServerObject } from '../../utils/types';

const API = 'IncomeAccounts';

export const API_INCOME_ACCOUNTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IncomeAccounts, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IncomeAccounts, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IIncomeAccounts
  extends FixServerObject<DynamicComponents.Schemas.IncomeAccount, 'id'> {}

type SchemaFields = Pick<IIncomeAccounts, 'title'>;

export const schemaIncomeAccounts: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
  accountCode: yup.string().required('rule-required'),
});

export class IncomeAccounts implements yup.InferType<typeof schemaIncomeAccounts> {
  id!: string;
  title = '';
  accountCode = '';
  isActive = true;
}

export interface IGridIncomeAccounts
  extends Pick<IIncomeAccounts, 'id' | 'title' | 'accountCode' | 'rowIndex' | 'isActive'> {}

export interface IDashboardIncomeAccountsArgs {
  search: string;
  orderBy: DynamicOrder;
}
export interface IDashboardIncomeAccount
  extends Pick<IIncomeAccounts, 'id' | 'title' | 'accountCode'> {}
