import React, { memo, useEffect, useState } from 'react';
import { SKIP_REFRESH_TOKEN_HEADER_NAME } from 'services/accounts';
import { getAuthTokens } from 'utils/cookies';
import { apiAppBackend, apiAppDynamic } from 'utils/service';

let isRefreshing = false;
let refreshingCall: Promise<any>;

const instances = [apiAppDynamic, apiAppBackend];

const SKIP_HEADER_NAME = SKIP_REFRESH_TOKEN_HEADER_NAME;

type SuccessArg = { token: string; refreshToken: string; expires: string };
interface Props {
  onRefresh: () => Promise<{
    token: string;
    refreshToken: string;
    expires: string;
  }>;
  onSuccess: (p: SuccessArg) => void;
  onError: (e: Error) => void;
  children: React.ReactNode;
}

export const AxiosInterceptorsRefreshToken = memo<Props>(
  ({ children, onRefresh, onSuccess, onError }) => {
    const [canRender, setCanRender] = useState(false);
    useEffect(() => {
      setCanRender(true);
      const result = instances.map((instance) => {
        const id = instance.interceptors.response.use(
          (response) => response,
          (error) => {
            const status = error?.response?.status || null;

            const { token, refreshToken } = getAuthTokens();

            if (SKIP_HEADER_NAME in error.config.headers) {
              return Promise.reject(error);
            }

            if (status !== 401 || !(token && refreshToken)) {
              return Promise.reject(error);
            }

            if (isRefreshing) {
              return refreshingCall.then(() => {
                return instance(error.config);
              });
            }
            isRefreshing = true;
            refreshingCall = onRefresh()
              .then((res) => {
                isRefreshing = false;
                const { token, refreshToken, expires } = res;
                onSuccess({ token, refreshToken, expires });
                return instance({
                  ...error.config,
                  headers: {
                    ...error.config.headers,
                    Authorization: 'Bearer ' + token,
                    [SKIP_HEADER_NAME]: true,
                  },
                });
              })
              .catch((err) => {
                isRefreshing = false;
                onError(err);
                return Promise.reject(err);
              });
            return refreshingCall;
          },
        );

        return { instance, id };
      });

      return () => {
        result.forEach(({ instance, id }) => {
          instance.interceptors.response.eject(id);
        });
      };
    }, [onRefresh, onSuccess, onError]);

    return <>{canRender ? children : null}</>;
  },
);
