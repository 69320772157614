import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'NamePrefixEngs';

export const API_NAME_PREFIXES_ENG = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<NamePrefixEng, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<NamePrefixEng, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface INamePrefixEng
  extends FixServerObject<DynamicComponents.Schemas.NamePrefixEng, 'id'> {}

type SchemaFields = Pick<INamePrefixEng, 'title'>;

export const schemaNamePrefixesEng: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
});

export class NamePrefixEng implements yup.InferType<typeof schemaNamePrefixesEng> {
  id!: string;
  title = '';
  isActive = true;
}

export interface IGridNamePrefixEng
  extends Pick<INamePrefixEng, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}
