import React, { memo, useEffect, useState } from 'react';
import { getAuthTokens } from 'utils/cookies';
import { apiAppBackend, apiAppDynamic } from 'utils/service';

const instances = [apiAppBackend, apiAppDynamic];

interface Props {
  children: React.ReactNode;
}
export const AxiosInterceptorsToken = memo<Props>(({ children }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    const ids = instances.map((instance) => {
      const id = instance.interceptors.request.use((config) => {
        const { token } = getAuthTokens();
        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: 'Bearer ' + token,
          };
        }
        return config;
      });
      return { id, instance };
    });

    setInit(true);
    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{init && children}</>;
});
