import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'LogisticClassifications';

export const API_LOGISTIC_CLASSIFICATIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<LogisticClassification, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<LogisticClassification, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ILogisticClassification
  extends FixServerObject<
    DynamicComponents.Schemas.LogisticClassification,
    'id' | 'rowIndex' | 'isOther' | 'title' | 'isActive'
  > {}

export const schemaLogisticClassification = yup.object({
  title: yup.string().required('rule-required'),
});

export class LogisticClassification implements yup.InferType<typeof schemaLogisticClassification> {
  id!: string;
  title = '';
  isActive = true;
  isOther = false;
  rank = 0;
}

export interface IGridLogisticClassification
  extends Pick<
    ILogisticClassification,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'isOther' | 'rank'
  > {}
