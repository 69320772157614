import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'InvoiceTypes';

export const API_INVOICE_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<InvoiceType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<InvoiceType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IInvoiceType
  extends FixServerObject<
    DynamicComponents.Schemas.InvoiceType,
    'id' | 'rowIndex' | 'step' | 'title'
  > {
  step: InvoiceStep;
}

export const schemaInvoiceTypes = yup.object({
  title: yup.string().required('rule-required'),
  externalAppKey: yup.number().required('rule-required'),
});

export enum InvoiceStep {
  COMPLETE = 3,
}

export class InvoiceType implements yup.InferType<typeof schemaInvoiceTypes> {
  id!: string;
  title = '';
  isActive = true;
  externalAppKey = 0;
  paymentRequired = false;
  rank = 0;
}

export interface IGridInvoiceType
  extends Pick<
    IInvoiceType,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'externalAppKey' | 'paymentRequired' | 'rank'
  > {}
