import * as yup from 'yup';

const API = 'UserPharmacistProfiles';

export const API_USER_PHARMACIST_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPharmacistProfile, 'appIdentityUserID'>) =>
    `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<UserPharmacistProfile, 'appIdentityUserID'>) =>
    `${API}/Delete/${data.appIdentityUserID}`,
  CREATE_FROM_EXCEL: `${API}/CreateBulkUsersFromExcel`,
};
export const schemaUserPharmacistProfile = yup.object({
  firstName: yup.string(),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  idNumber: yup.string().required('rule-required'),
  email: yup.string().nullable().required('rule-required').email('rule-email'),
  phoneMobile: yup.string().required('rule-required'),
  graduationYear: yup.number().min(1902, 'rule-min-length').max(2022, 'rule-max-length'),
});

export class UserPharmacistProfile implements yup.InferType<typeof schemaUserPharmacistProfile> {
  id!: string;
  isActive = true;
  lastName = '';
  firstName = '';
  fullName = '';
  idNumber = '';
  email = '';
  phoneMobile = '';
  cityTitle = '';
  licenseNumber = 0;
  position = '';
  workPlaceID = '';
  namePrefixID!: number;
  namePrefixEngID!: number;
  firstNameEng = '';
  lastNameEng = '';
  pharmacistCode = '';
  remarks = '';
  emailAdditional = '';
  mobilePhone = '';
  phoneWork = '';
  phoneHome = '';
  fax = '';
  address = '';
  zipCode = '';
  cityID = '';
  regionID = '';
  memberTypeID = '';
  country = '';
  nationalityID = '';
  dateOfBirth = new Date().toISOString();
  graduationYear!: number;
  positionOrg = '';
  workPlaceTypeID = '';
  stateEmployee = false;
  genderID = false;
  clinicalPharmacist = false;
  isAllowToSendNewsletter = false;
  isAllowToSendNotification = false;
  isAllowToSendSms = false;
  distributionType = 0;
  appIdentityUserID = '';
}
export interface IUserPharmacistProfile extends UserPharmacistProfile {
  firstName: string;
  lastName: string;
  firstNameEng: string;
  lastNameEng: string;
  pharmacistCode: string;
  memberTypeID: string;
  cityID: string;
}
