import { apiAppDynamic } from 'utils/service';
import { API_AUTH, CurrentAppUser, JWT, SKIP_REFRESH_TOKEN_HEADER_NAME } from './models';

export * from './models';

class Service {
  async refreshToken(p: { token?: string; refreshToken?: string }) {
    return apiAppDynamic.post<JWT['jwt']>(API_AUTH.REFRESH_TOKEN, p, {
      headers: { [SKIP_REFRESH_TOKEN_HEADER_NAME]: true },
    });
  }

  async getCurrentUser() {
    return apiAppDynamic.get<CurrentAppUser>(API_AUTH.GET_CURRENT_USER);
  }

  async generatePassword(data: { email: string }) {
    return apiAppDynamic.post(API_AUTH.GENERATE_PASSWORD, data);
  }

  async sendCode(data: { email: string; code: string }) {
    return apiAppDynamic.post<JWT>(API_AUTH.LOGIN, data);
  }

  async logout() {
    return apiAppDynamic.post(API_AUTH.LOGOUT);
  }
}

export const ServiceAccounts = new Service();
