import { FixServerObject } from 'utils/types';

const API = 'MediaUploads';

export const API_MEDIA_UPLOADS = {
  UPLOAD_FILE: `${API}/UploadFileToCloud`,
  UPLOAD_HTML: `${API}/UploadHtmlToCloud`,
  REMOVE_FILE: (data: { filePath: string }) =>
    `${API}/RemoveFileFromCloud?filePath=${encodeURIComponent(data.filePath)}`,
};

export interface MediaUpload
  extends FixServerObject<
    DynamicComponents.Schemas.CloudFileViewModel,
    'fileName' | 'content' | 'filePath'
  > {}
