import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserPharmacistProfile } from 'services/user-pharmacist-profiles';
import { actionAccountLogout } from 'store/auth';
import { DateValue } from 'utils/dates';
import { actionUserPharmacistProfilesGetAllDynamic } from './actions';
import { BlockedInvoice, BlockedInvoiceReason, NAMESPACE } from './helpers';

interface Filters {
  firstName: string;
  lastName: string;
  idNumber: string;
  email: string;
  mobilePhone: string;
  isActiveOnly: boolean;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}
export interface IGridUserPharmacistProfiles
  extends Pick<
    UserPharmacistProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'isActive'
    | 'fullName'
    | 'idNumber'
    | 'email'
    | 'phoneMobile'
    | 'cityTitle'
    | 'licenseNumber'
    | 'position'
  > {
  workPlaceTitle: string;
}

interface BlockInvoice {
  userPharmacistProfileID: string;
  startDate: DateValue;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridUserPharmacistProfiles[];
  filters: Filters;
  pagination: Pagination;
  orderBy: OrderBy;
  dataProfileInvoiceDetailsLength: number;
  blockInvoices: BlockInvoice[];
  blockedInvoices: Record<string, BlockedInvoice>;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    dataProfileInvoiceDetailsLength: 0,
    isInit: false,
    data: [],
    filters: {
      firstName: '',
      lastName: '',
      idNumber: '',
      email: '',
      mobilePhone: '',
      isActiveOnly: true,
    },
    pagination: {
      take: 25,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'firstName, lastName',
      order: 'asc',
    },
    blockInvoices: [],
    blockedInvoices: {},
  };
};

const slice = createSlice({
  name: NAMESPACE,
  initialState: initState(),
  reducers: {
    addInvoiceBlocker(
      state,
      action: PayloadAction<{ invoiceID: string; reason: BlockedInvoiceReason }>,
    ) {
      state.blockedInvoices[action.payload.invoiceID] = {
        reason: action.payload.reason,
        startTimestamp: new Date().getTime(),
      };
    },
    removeInvoiceBlocker(state, action: PayloadAction<string>) {
      delete state.blockedInvoices[action.payload];
    },
    actionUserPharmacistProfilesFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionUserPharmacistProfilesFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionUserPharmacistProfilesOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    actionDataProfileInvoiceDetailsLengthSet(state, action: PayloadAction<number>) {
      state.dataProfileInvoiceDetailsLength = action.payload;
    },

    actionUserPharmacistProfilesBlock(state, action: PayloadAction<BlockInvoice>) {
      state.blockInvoices = [...state.blockInvoices, action.payload];
    },
    actionUserPharmacistProfilesUnBlock(
      state,
      action: PayloadAction<Pick<BlockInvoice, 'userPharmacistProfileID'>>,
    ) {
      state.blockInvoices = state.blockInvoices.filter(
        (item) => item.userPharmacistProfileID !== action.payload.userPharmacistProfileID,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionUserPharmacistProfilesGetAllDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionUserPharmacistProfilesGetAllDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value;
        state.pagination.count = count;
      })
      .addCase(actionUserPharmacistProfilesGetAllDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

export const {
  actionUserPharmacistProfilesOrderBySet,
  actionUserPharmacistProfilesFiltersSet,
  actionDataProfileInvoiceDetailsLengthSet,

  actionUserPharmacistProfilesBlock,
  actionUserPharmacistProfilesUnBlock,
} = slice.actions;
export const actionsUserPharmacistProfiles = slice.actions;
export const reducerUserPharmacistProfiles = slice.reducer;
