import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Suppliers';

export const API_SUPPLIERS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  DELETE: (data: Pick<Supplier, 'id'>) => `${API}/Delete/${data.id}`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Supplier, 'id'>) => `${API}/Patch/${data.id}`,
};

export interface ISupplier extends FixServerObject<DynamicComponents.Schemas.Supplier, 'id'> {}

export interface ISupplierInvoice {
  firstInvoice: string;
  lastInvoice: string;
  numberOfInvoices: number;
}

type SchemaFields = Pick<ISupplier, 'email' | 'companyName' | 'companyNumber'>;

export const schemaSuppliers: yup.SchemaOf<SchemaFields> = yup.object({
  email: yup.string().email('rule-email'),
  companyName: yup.string().required('rule-required'),
  companyNumber: yup.string().required('rule-required'),
});

export class Supplier implements yup.InferType<typeof schemaSuppliers> {
  id!: string;
  companyName = '';
  companyNumber = '';
  phone1 = '';
  phone2 = '';
  phoneMobile = '';
  address = '';
  email = '';
  isActive = true;
  bankAccountDetails = '';
}

export interface IGridSupplier
  extends Pick<
    ISupplier,
    | 'id'
    | 'companyName'
    | 'companyNumber'
    | 'phone1'
    | 'phone2'
    | 'phoneMobile'
    | 'email'
    | 'isActive'
  > {
  numberOfInvoices: number;
}
