import { eventChannel } from 'redux-saga';
import { call, cancel, fork, put, select, take } from 'typed-redux-saga';
import { notifyErrorSaga } from 'utils/sagas';
import { apiRtk } from 'utils/service';
import { actionAccountGetUser, selectAccountUser } from '../auth';
import { actionsCustomers } from '../customers';
import { actionsUserPharmacistProfiles } from '../user-pharmacist-profiles';

// Helper function to create EventChannel
function* createSSEChannel() {
  const user = yield* select(selectAccountUser);

  function* handleError(message: string) {
    yield* notifyErrorSaga({ message });
  }

  return eventChannel((emit) => {
    let eventSource: EventSource | null;

    function handleSuccessUserInvoice(invoiceIDs: string[]) {
      invoiceIDs.forEach((invoiceID) => {
        emit(actionsUserPharmacistProfiles.removeInvoiceBlocker(invoiceID));
        emit(
          apiRtk.util.invalidateTags([
            { type: 'UserPharmacistProfileInvoices' },
            { type: 'UserPharmacistProfileInvoiceDetails' },
          ]),
        );
      });
    }
    function handleSuccessCustomerInvoice(invoiceIDs: string[]) {
      invoiceIDs.forEach((invoiceID) => {
        emit(actionsCustomers.removeInvoiceBlocker(invoiceID));
        emit(
          apiRtk.util.invalidateTags([
            { type: 'CustomerInvoices' },
            { type: 'CustomerInvoiceDetails' },
          ]),
        );
      });
    }

    function connect() {
      if (!user) throw new Error('User is not exist');

      eventSource = new EventSource(`/api/sse/events/${user.appUserID}`);

      eventSource.onopen = (ev) => {
        console.log('SSE open:', ev);
      };
      eventSource.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(
          event.data,
        ) as BackendPaths.SseControllerSubscribeEvents.Responses.$200;

        switch (data.type) {
          case 'user-pharmacist-profile-invoice-details-duplicated-fulfilled':
          case 'user-pharmacist-profile-invoice-details-converted-fulfilled': {
            handleSuccessUserInvoice([data.payload.invoiceID, data.payload.newInvoiceID]);
            break;
          }
          case 'user-pharmacist-profile-invoice-receipt-fulfilled': {
            handleSuccessUserInvoice([...data.payload.invoiceIDs, data.payload.newInvoiceID]);
            break;
          }

          case 'customer-invoice-details-duplicated-fulfilled':
          case 'customer-invoice-details-converted-fulfilled': {
            handleSuccessCustomerInvoice([data.payload.invoiceID, data.payload.newInvoiceID]);
            break;
          }
          case 'customer-invoice-receipt-fulfilled': {
            handleSuccessCustomerInvoice([...data.payload.invoiceIDs, data.payload.newInvoiceID]);
            break;
          }

          case 'user-pharmacist-profile-invoice-details-duplicated-rejected':
          case 'user-pharmacist-profile-invoice-details-converted-rejected':
          case 'user-pharmacist-profile-invoice-receipt-rejected':
          case 'customer-invoice-details-duplicated-rejected':
          case 'customer-invoice-details-converted-rejected':
          case 'customer-invoice-receipt-rejected': {
            handleError(data.payload.error);
            break;
          }
        }
      };

      eventSource.onerror = (error) => {
        console.error('SSE error:', error);

        eventSource?.close();

        setTimeout(connect, 10_000);
      };
    }

    connect();

    return () => {
      eventSource?.close();
    };
  });
}

// Worker saga to listen for SSE events when the account is successfully fetched
function* listenToSSE() {
  const channel = yield* call(createSSEChannel);

  try {
    while (true) {
      const action = yield* take(channel); // Take each event and dispatch to Redux
      yield* put(action); // Dispatch the action (addEvent)
    }
  } catch (error) {
    console.error('Error in SSE connection:', error);
  } finally {
    channel.close(); // Clean up when done
  }
}

// Watcher saga that starts the EventChannel when account fetch is successful
function* watchAccountGetUser() {
  let sseTask = null; // Track the current SSE task

  while (true) {
    yield* take(actionAccountGetUser.fulfilled.type);

    // If there's an existing task, cancel it before starting a new one
    if (sseTask) {
      yield* cancel(sseTask);
    }

    // Fork the listenToSSE worker saga to handle the new EventChannel
    sseTask = yield* fork(listenToSSE);

    yield* take(actionAccountGetUser.rejected.type);

    // Cancel the existing SSE task when failure occurs
    yield cancel(sseTask);

    sseTask = null; // Reset the task reference
  }
}
export const sagasSse = [watchAccountGetUser()];
