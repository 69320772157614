import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ResponsivenessToTreatments';

export const API_RESPONSIVENESS_TO_TREATMENTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ResponsivenessToTreatment, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ResponsivenessToTreatment, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IResponsivenessToTreatment
  extends FixServerObject<
    DynamicComponents.Schemas.ResponsivenessToTreatment,
    'id' | 'rowIndex' | 'title' | 'isActive'
  > {}

export const schemaResponsivenessToTreatments = yup.object({
  title: yup.string().required('rule-required'),
});

export class ResponsivenessToTreatment
  implements yup.InferType<typeof schemaResponsivenessToTreatments>
{
  id!: string;
  title = '';
  isActive = true;
  rank = 0;
}

export interface IGridResponsivenessToTreatment
  extends Pick<IResponsivenessToTreatment, 'id' | 'title' | 'rowIndex' | 'isActive' | 'rank'> {}
