import { ActionMatchingPattern } from '@redux-saga/types';
import { addSeconds } from 'date-fns';
import { apiCustomerInvoices } from 'services/customer-invoices';
import { all, call, delay, put, select, takeEvery } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';
import { BlockedInvoiceReason } from './helpers';
import { selectCustomersBlockedInvoices } from './selectors';
import { actionsCustomers } from './slice';

const getDelayInSeconds = (reason: BlockedInvoiceReason) => {
  switch (reason) {
    case BlockedInvoiceReason.IMPORT_EXCEL:
      return 30;
    default:
      return 60;
  }
};

const ACTION_DUPLICATE = apiCustomerInvoices.endpoints.duplicateCustomerInvoice.matchFulfilled;
const ACTION_CONVERT = apiCustomerInvoices.endpoints.convertCustomerInvoice.matchFulfilled;
const ACTION_RECEIPT = apiCustomerInvoices.endpoints.createCustomerInvoiceReceipt.matchFulfilled;

function* workerBlockedInvoices() {
  const blocked = yield* select(selectCustomersBlockedInvoices);

  for (let invoiceID in blocked) {
    const blocker = blocked[invoiceID];

    const estimatedEndTime = addSeconds(blocker.startTimestamp, getDelayInSeconds(blocker.reason));

    // remove the blocker
    if (estimatedEndTime < new Date()) {
      yield* put(actionsCustomers.removeInvoiceBlocker(invoiceID));
      yield* put(
        apiRtk.util.invalidateTags([
          { type: 'UserPharmacistProfileInvoices' },
          { type: 'UserPharmacistProfileInvoiceDetails' },
        ]),
      );
    }
  }
}
function* sagaBlockedInvoices() {
  while (true) {
    // each 20 seconds
    yield* delay(20_000);
    yield* call(workerBlockedInvoices);
  }
}

function* watchDuplicate(action: ActionMatchingPattern<typeof ACTION_DUPLICATE>) {
  const {
    meta: {
      arg: { originalArgs },
    },
    payload,
  } = action;

  const invoices = [
    // Invoice from where we are copying
    originalArgs,
    // New invoice to where we are copying
    payload.id,
  ];

  yield* all(
    invoices.map((invoiceID) => {
      return put(
        actionsCustomers.addInvoiceBlocker({
          invoiceID,
          reason: BlockedInvoiceReason.DUPLICATE,
        }),
      );
    }),
  );
}
function* watchConvert(action: ActionMatchingPattern<typeof ACTION_CONVERT>) {
  const {
    meta: {
      arg: { originalArgs },
    },
    payload,
  } = action;

  const invoices = [
    // Invoice from where we are converting
    originalArgs.invoiceID,
    // New invoice to where we are converting,
    payload.id,
  ];

  yield* all(
    invoices.map((invoiceID) => {
      return put(
        actionsCustomers.addInvoiceBlocker({
          invoiceID,
          reason: BlockedInvoiceReason.CONVERT,
        }),
      );
    }),
  );
}
function* watchReceipt(action: ActionMatchingPattern<typeof ACTION_RECEIPT>) {
  const {
    meta: {
      arg: { originalArgs },
    },
    payload,
  } = action;

  const invoiceIDs = originalArgs.invoiceIDs;

  const invoices = [...invoiceIDs, payload.id];

  yield* all(
    invoices.map((invoiceID) => {
      return put(
        actionsCustomers.addInvoiceBlocker({
          invoiceID,
          reason: BlockedInvoiceReason.RECEIPT,
        }),
      );
    }),
  );
}

export const sagasCustomers = [
  sagaBlockedInvoices(),
  takeEvery(ACTION_DUPLICATE, watchDuplicate),
  takeEvery(ACTION_CONVERT, watchConvert),
  takeEvery(ACTION_RECEIPT, watchReceipt),
];
